
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/PageLayout';
import PageHead from '../components/PageHead';
import Faq from '../components/Faq';
import { setLinks } from '../util/pagetools';
import { localizer } from '../util/localizer';

export default function FaqPage(props) {
  const ctx = props.pageContext;
  const sitedata = props.data.siteYaml;
  sitedata.language = ctx.locale;

  const toplinks = setLinks(sitedata.pagelinks);
  const sitename = sitedata.sitename;
  const siteurl = sitedata.siteurl;
  const footer = { ...sitedata.footer, toplinks, sitename, siteurl };

  return (
    <Layout sitename={sitedata.sitename} links={toplinks} footer={footer} locfn={localizer(ctx)}>
      <PageHead lang={ctx.locale} sitename={sitename} siteurl={siteurl} meta={sitedata.metaDescription} />
      <Faq config={sitedata.guide} theme="p-4-8" />
    </Layout>
  );
};

export const query = graphql`
  query siteFaqQ($locale: String, $variation: String) {
    siteYaml(locale: {eq: $locale}, variation: {eq: $variation}) {
      sitename
      siteurl
      metaDescription
      pagelinks {
        title
        linkto
      }
      guide {
        title
        intro
        faq {
          title
          displayAs
          text
        }
      }
      footer {
        theme
        text
        links {
          label
          to
          newtab
        }
      }
      errorpage {
        header
        subheader
      }
    }
  }`;
