/**
 * Render a FAQ
 * Supported props: config theme
 */
import React from 'react';
import Safe from './Safe';

export default function FaqSection(props) {
  const questions = (Array.isArray(props.config.faq) ? props.config.faq : []);
  const rootAttribs = {id: 'faq'};
  if (props.theme) rootAttribs.className = props.theme;

  return (
    <div {...rootAttribs}>
      <h2 className="text-center">{props.config.title}</h2>
      <p>{props.config.intro}</p>
      {questions.map((item, index) => (
        <div key={`q-${index}`}>
          <div className="q-title">{item.title}</div>
          {renderAnswer(item, 'q-answer')}
        </div>
      ))}
    </div>
  );
};

function renderAnswer(config, itemStyle) {
  if (config.displayAs==='list') {
    return <ul>{config.text.map((item, index) => <Safe key={`p-${index}`} type="li" className={itemStyle} content={item} />)}</ul>;
  }
  return config.text.map((item, index) => <Safe type="p" key={`p-${index}`} className={itemStyle} content={item} />);
}
